.chat-bubble {
    max-width: 60%;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 20px;
    color: white;
    line-height: 1.5;
  }
  
  .own-message {
    background-color: #0b93f6;
    align-self: flex-end;
  }
  
  .other-message {
    background-color: #e5e5ea;
    color: black;
    align-self: flex-start;
  }

.chat-timestamp {
    text-align: right;
    font-size: smaller;
  }