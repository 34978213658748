/* General styling for the carousel controls */
.carousel-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Ensure each control element (arrows and dots) is centered and takes equal space */
.carousel-controls > div {
  flex: 1;
  text-align: center;
}

/* Style for the custom previous and next arrows */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important; /* Ensure the arrows are always visible */
}

/* Hide the default arrow icons */
.ant-carousel .slick-prev:before,
.ant-carousel .slick-next:before {
  display: none;
}

/* Additional styling for positioning and padding of arrows */
.ant-carousel .slick-prev {
  left: 0;
  z-index: 1;
  padding-left: 10px;
}

.ant-carousel .slick-next {
  right: 0;
  z-index: 1;
  padding-right: 10px;
}

/* Ensure visibility of disabled arrows */
.ant-carousel .slick-disabled {
  opacity: 1 !important;
  pointer-events: none; /* Prevent interaction if disabled */
  visibility: visible !important; /* Ensure visibility even when disabled */
}

.ant-spin-content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
