img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 600px) {
  img {
    width: 50%;
    margin: 0 auto;
  }

  h1,
  p {
    padding: 0 10px;
  }
}
