.LeadDetail {
  padding: 20px;
}

.left-align {
  text-align: left;
}
pre {
  white-space: pre-wrap;
}

.card-title {
  max-width: 25vw; /* 25% of the viewport width */
  white-space: unset;
  word-wrap: break-word;
}

.title-container {
  display: flex;
  align-items: center;
}

.question-button {
  margin-left: 10px; /* Adjust as needed */
}

.feedback-textarea {
  width: 90%;
}