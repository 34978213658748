.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 10% 40% 40% 10%;
  height: calc(100vh - 60px); /* Subtract the total height of the gaps */
  width: 100%;
  gap: 20px;
}

#prompt {
  grid-row: 1;
  grid-column: 1;
}

#instructions {
  grid-row: 2;
  grid-column: 1;
}

#context {
  grid-row: 3;
  grid-column: 1;
}

.button-container {
  grid-row: 4;
  grid-column: 1;
}

#results {
  grid-row: 1 / span 2;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#results_area {
  flex: 1;
  height: 100%;
}

#original {
  grid-row: 3;
  grid-column: 2;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container button {
    margin-top: 10px;
}
