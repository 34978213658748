.taskContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/* Additional CSS rules... */
.task-container input[type='text'],
.task-container input[type='datetime-local'],
.task-container textarea {
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.task-container button {
  padding: 10px 15px;
  margin: 10px;
  background-color: #007bff;
  /* background: linear-gradient(
    230.52deg,
    #16febf 0.37%,
    #338ff0 48.45%,
    #a328ff 93.73%
  ); */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.task-container button:hover {
  background-color: #0056b3;
}
