.header {
    display: block !important;
}

.carousel-custom .slick-next {
    z-index: 1;
    color: black;
    font-size: 14px;
    margin-right: 5px;
}

.carousel-custom .slick-prev {
    z-index: 1;
    color: black;
    font-size: 14px;
    margin-left: 5px;
}

.carousel-custom .slick-slide {
    text-align: left;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-text {
    font-size: 14px;
    color: black;
    padding: 5px;
    border-radius: 4px;
    margin: auto;
    max-width: 90%;
    word-wrap: break-word;
}
